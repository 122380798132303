@import "styles/mixins";

.errorContainer {
  background-color: var(--color-cotton);
  padding: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;

  @include mobile {
    flex-direction: column;
    padding: 0;
    justify-content: center;
  }

  .title {
    font-size: 4rem;
    font-weight: 700;
    color: var(--color-forest);
    max-width: 50%;
  }

  .image {
    background-image: url("../public/assets/illustrations/not_found.jpg");
    height: 10rem;
    position: relative;
    border-radius: 1rem;
    padding: 2rem;
    background-size: cover;
    text-align: center;
  }
}

.maintenancePage {
  background-color: var(--color-cotton);
  padding: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;

  @include mobile {
    flex-direction: column;
    padding: 0;
    justify-content: center;
  }

  .title {
    font-size: 4rem;
    font-weight: 700;
    color: var(--color-forest);
    max-width: 50%;
  }

  .image {
    background-image: url("../public/assets/illustrations/not_found.jpg");
    height: 10rem;
    position: relative;
    border-radius: 1rem;
    padding: 2rem;
    background-size: cover;
    text-align: center;
  }
}
